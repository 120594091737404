import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Hidden } from '@material-ui/core';
import CardNumber from 'src/shared/components/card-number.component';
// type Props = {};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    // flexGrow: 1,
    backgroundColor: '#34ccff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  text: {
    textAlign: 'justify',
    marginTop: '1em',
  },
  title: {
    color: '#183650',
    fontSize: theme.typography.pxToRem(32),
    textAlign: 'justify',
    textTransform: 'uppercase',
    '&::before': {
      borderBottom: '3px solid #34ccff',
      borderRadius: '2px',
      content: '""',
      display: 'block',
      marginBottom: '15px',
      width: '50px',
    },
  },
  titleWrap: {
    // borderTop: '1px solid red',
  },
}));

export default function Numbers() {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.root}>
      <Hidden mdDown>
        <Grid item lg={2} />
      </Hidden> 
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <CardNumber number="12,345" text="Proyectos completados" icon="AssignmentTurnedInIcon" />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <CardNumber number="1,234" text="Flota de Vehículos" icon="LocalShippingIcon"/>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <CardNumber number="123" text="Clientes" icon="ThumbUpIcon"/>
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <CardNumber number="12" text="Miembros del equipo" icon="GroupIcon"/>
      </Grid>
      <Hidden mdDown>
        <Grid item lg={2} />
      </Hidden> 
    </Grid>
  );
}