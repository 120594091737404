import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonIcon from '@material-ui/icons/Person';
// type Props = {};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  text: {
    textAlign: 'justify',
    marginTop: '1em',
  },
  title: {
    color: '#183650',
    fontSize: theme.typography.pxToRem(32),
    textAlign: 'justify',
    textTransform: 'uppercase',
    '&::before': {
      borderBottom: '3px solid #34ccff',
      borderRadius: '2px',
      content: '""',
      display: 'block',
      marginBottom: '15px',
      width: '50px',
    },
  },
  pos: {
    fontWeight: 'bolder',
    letterSpacing: theme.spacing(0.25),
    textTransform: 'uppercase'
  },
}));

const contacts = [
{
position: 'Gerencia',
name: 'Ing. José Antonio Martínez Figueroa',
phone: '+52 314 120 2872',
email: 'gerencia@transportesmafa.com.mx',
},
{
position: 'Operaciones',
name: 'Ing. Brian Antonio Martínez Rubio',
phone: '+52 314 174 4994',
email: 'logistica@transportesmafa.com.mx',
},
{
  position: 'Administración',
  name: 'Lic. Elizabeth Ramirez',
  phone: '+52 33 33513430',
  email: 'administracion@transportesmafa.com.mx',
},
];

export default function Contact() {
  const classes = useStyles();
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={10}>
        <Typography variant="h3" align="center">
          Contacto
        </Typography>
      </Grid>
      {contacts.map((contact, index) => (
        <Grid item xs={12} md={3} key={`contact-${index}`}>
          <Paper elevation={0} className={classes.paper}>
            <List dense>
              <ListItem>
                <ListItemText primary={contact.position} className={classes.pos} />
              </ListItem>
              <ListItem>
                <ListItemIcon><PersonIcon /></ListItemIcon>
                <ListItemText primary={contact.name} />
              </ListItem>
              <ListItem>
                <ListItemIcon><PhoneIphoneIcon /></ListItemIcon>
                <ListItemText primary={<a href={`tel:${contact.phone}`}>{contact.phone}</a>} />
              </ListItem>
              <ListItem>
                <ListItemIcon><MailOutlineIcon /></ListItemIcon>
                <ListItemText primary={<a href={`mailto:${contact.email}`}>{contact.email}</a>} />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
