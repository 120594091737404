import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type Props = {
  isOpen: boolean,
  goTo: Function,
  setOpen: Function,
}

const menu: {[menu: string]: string} = {
  'Sobre Nosotros': 'welcome',
  'Cobertura': 'locations',
  'Servicios': 'services',
  'Ubicación': 'location', 
  'Contacto': 'contact',
}

export default function SwipeableTemporaryDrawer({ isOpen, goTo, setOpen }: Props) {
  const classes = useStyles();
  const toggleDrawer = (event: any, item: string) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    goTo(menu[item]);
    setOpen(!isOpen);
  };

  const list = (anchor: any) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List>
        {Object.keys(menu).map((item, index) => (
          <ListItem button key={menu[item]} onClick={(event) => toggleDrawer(event, item)}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onClose={() => { setOpen(!isOpen)}}
        onOpen={() => {}}
      >
        {list("bottom")}
      </SwipeableDrawer>
    </div>
  );
}
