import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import GridGallery from './grid-gallery.component';
import SimpleAccordion from './accordion.component';
// type Props = {};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  text: {
    textAlign: 'justify',
    marginTop: '1em',
    fontSize: theme.typography.pxToRem(18),
  },
  title: {
    color: '#183650',
    fontSize: theme.typography.pxToRem(32),
    textAlign: 'justify',
    textTransform: 'uppercase',
    '&::before': {
      borderBottom: '3px solid #34ccff',
      borderRadius: '2px',
      content: '""',
      display: 'block',
      marginBottom: '15px',
      width: '50px',
    },
  },
  titleWrap: {
    // borderTop: '1px solid red',
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
    marginTop: `${theme.spacing(2)}px`,
  },
  progress: {
    width: '100%',
  }
}));

const items = [
  'Nos enorgullece ser una empresa 100% Manzanillence formada en el año 2003 que ha ido crecido y se ha desarrollado a la par de nuestros clientes. Ya son mas de 16 años de crecimiento sostenido: una infraestructura física y humana con un sólo objetivo, su completa satisfacción.',
  'Somos una empresa de Servicios de transporte puerta a puerta, de mercancías y productos en nuestras rutas nacionales, con diferentes servicios de apoyo.',
  'Somos líderes en el ramo y satisfacemos las necesidades del cliente y del mercado. Reconocemos el valor de nuestro personal, fomentando su constante superación para contar con recursos humanos calificados y comprometidos. Buscamos la excelencia y la rentabilidad mediante los niveles óptimos de competitividad, servicio, calidad, seguridad y protección del medio ambiente. Desarrollamos procesos de operación, de innovación y de mejora continua, modernizando día con día unidades, equipo, infraestructura y tecnología.',
];

export default function Welcome() {
  const classes = useStyles();
  const [counter, setCounter] = useState<number>(1);
  const [textItem, setTextItem] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter >= 49) {
        setCounter(-5);
        setTextItem(textItem >= items.length - 1 ? 0 : textItem + 1);
      } else {
        setCounter(counter + 2);
      }
    }, 350);
    return () => clearInterval(interval);
  }, [counter, textItem]);

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={12} md={10}>
        <Paper elevation={0} className={classes.paper}>
          <div className={classes.titleWrap}>
            <Typography className={classes.title} variant="h3">
              ¿Quiénes somos?
            </Typography>
          </div>
          {items.map((item, index) => (
            index === textItem && <Typography key={index} className={clsx(classes.text, 'animate__animated', 'animate__fadeIn animate__delay-1s')}>{item}</Typography>
          ))}
          <FormatQuoteIcon />
          <MobileStepper
            variant="progress"
            steps={50}
            position="static"
            activeStep={counter}
            className={classes.root}
            nextButton={null}
            backButton={null}
            classes={{
              root: classes.stepper,
              progress: classes.progress,
            }}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <GridGallery />
      </Grid>
      <Grid item xs={12} md={10}>
        <SimpleAccordion />
      </Grid>
    </Grid>
  );
}
