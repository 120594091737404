import React from 'react';
// import Swiper core and required components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller, Thumbs, Autoplay, EffectFade, Lazy } from 'swiper';

// Import Swiper styles
import "swiper/components/effect-cube/effect-cube.scss";
import "swiper/swiper-bundle.css";
import "src/styles.css";


SwiperCore.use([Controller, Thumbs, Autoplay, EffectFade, Lazy]);

function Slider() {
  // const [thumbsSwiper, setThumbsSwiper] = useState();
  // const [controlledSwiper, setControlledSwiper] = useState();

  const slides = [];
  for (let i = 0; i < 3; i += 1) {
    slides.push(
      <SwiperSlide key={`slide-${i}`} tag="li">
        <img
          alt={`Slide ${i}`}
          loading="lazy"
          src={`/image/${i + 1}.jpeg`}
          style={{ listStyle: 'none' }}
        />
      </SwiperSlide>
    );
  }

  const thumbs = [];
  for (let i = 0; i < 5; i += 1) {
    thumbs.push(
      <SwiperSlide key={`thumb-${i}`} tag="li" style={{ listStyle: 'none' }}>
        <img
          alt={`Thumbnail ${i}`}
          loading="lazy"
          src={`https://picsum.photos/id/${i}/163/100`}
        />
      </SwiperSlide>
    );
  }

  const slides2 = [];
  for (let i = 9; i < 14; i += 1) {
    slides2.push(
      <SwiperSlide key={`slide-${i}`} tag="li">
        <img
          alt={`Slide ${i}`}
          loading="lazy"
          src={`https://picsum.photos/id/${i + 1}/500/300`}
          style={{ listStyle: 'none' }}
        />
      </SwiperSlide>
    );
  }

  return (
    <React.Fragment>
      <Swiper
        id="main"
        // thumbs={{ swiper: thumbsSwiper }}
        lazy={{loadOnTransitionStart: true}}
        // controller={{ control: controlledSwiper }}
        tag="section"
        wrapperTag="ul"
        // navigation
        // pagination
        spaceBetween={0}
        slidesPerView={1}
        // onInit={(swiper) => console.log('Swiper initialized!', swiper)}
        // onSlideChange={(swiper) => { console.log('Slide index changed to: ', swiper.activeIndex); }}
        // onReachEnd={() => console.log('Swiper end reached')}
        autoplay={{ delay: 3000, }}
        speed={1000}
       effect="fade"
      >
        {slides}
      </Swiper>
    </React.Fragment>
  );
}

export default Slider;