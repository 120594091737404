import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      // maxWidth: 752,
    },
    demo: {
      // backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
  }),
);

const locations: Array<string> = [
  'GUADALAJARA',
  'SILAO',
  'CELAYA',
  'SALAMANCA',
  'AGUASCALIENTES',
  'GUANAJUATO',
  'ZACATECAS',
  'TOLUCA',
  'MEXICO',
  'COAHUILA',
  'MONTERREY',
  'QUERETARO',
  'LEON',
  'TAMULIPAS',
  'CHIHUAHUA',
  'CANCÚN',
  'TIJUANA'
];

export default function Locations() {
  const classes = useStyles();
  const [current, setCurrent] = React.useState(0);
  const animations = ['animate__fadeInRight', 'animate__fadeInLeft', 'animate__fadeInUp'];

  useEffect(() => {
    const interval: NodeJS.Timeout = setInterval(() => setCurrent((prev) => prev < locations.length-1 ? prev+1 : 0), 2100);

    return (): void => clearInterval(interval);
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4" align="center"> 
        Cobertura Nacional
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" align="center" className={`animate__animated ${animations[current % 3]} ${current}`}> 
            {locations[current]}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}