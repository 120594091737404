import React from 'react';

export default function LocationMap() {
  return ( 
    <div>
      <iframe
        width="100%"
        height="500"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Sierra%20de%20Zacatecas%2039,%20Lomas%20de%20Gregorio,%2028876%20Manzanillo,%20Col.&t=&z=17&ie=UTF8&iwloc=&output=embed"
        // src="https://www.google.com/maps/d/u/0/embed?mid=1FpplXzbOirbzdlIKXoC9o_L3VGMsDvLX"
        frameBorder="0"
        scrolling="no"
        marginHeight={0}
        marginWidth={0}
        title="map"
      >
      </iframe>
    </div>
  );
}
