import React from 'react';
// import clsx from 'clsx';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: '#183650',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    // padding: `${theme.spacing(10)}px 0px`,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  text: {
    textAlign: 'justify',
    marginTop: '1em',
    color: 'white',
    fontSize: theme.typography.pxToRem(20),
  },
  mainTitle: {
    color: 'white',
    fontSize: theme.typography.pxToRem(32),
    textAlign: 'justify',
    textTransform: 'uppercase',
    '&::before': {
      borderBottom: '3px solid #34ccff',
      borderRadius: '2px',
      content: '""',
      display: 'block',
      marginBottom: '15px',
      width: '50px',
    },
  },
  services: {
    width: '400px',
    margin: '0 auto',
    // marginRight: theme.spacing(8),
    alignSelf: 'center',
  },

  root2: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    // backgroundColor: theme.palette.background.paper,
    color: theme.palette.background.paper,
  },
  gridList: {
    width: 800,
    // height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  title: {
    textAlign: 'center',
    color: 'white',
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.1) 70%, rgba(0,0,0,0) 100%)',
    // background: 'transparent',
  },
  serviceImg: {
    marginBottom: 10,
    width: 64,
  },
  serviceTile: {
    textAlign: 'center',
  }
}));

const tileData = [
  {
    img: '/image/truck.svg',
    title: 'Tractocamiones en full',
    // author: 'author',
  },
  {
    img: '/image/simpletruck.svg',
    title: 'Tractocamines en sencillo',
    // author: 'author',
  },
  {
    img: '/image/torton.svg',
    title: 'Torton',
    // author: 'author',
  },
  {
    img: '/image/truck-1.svg',
    title: 'Camionetas de 1.0 tonelada',
    // author: 'author',
  },
  {
    img: '/image/truck-2.svg',
    title: 'Camionetas de 3.5 toneladas',
    // author: 'author',
  },
  {
    img: '/image/lifter.svg',
    title: 'Desconsolidación y Consolidación de Mercancías',
    // author: 'author',
  },
  {
    img: '/image/container.svg',
    title: 'Patio para resguardo de carga suelta y contenedores',
    // author: 'author',
  },
  {
    img: '/image/pallet.svg',
    title: 'Armado y emplayado de Pallets',
    // author: 'author',
  },
  {
    icon: <VerifiedUserIcon style={{ width:64, height:64, marginTop: "30px", fill:"#de004e" }} />,
    title: 'Custodias Terrestres',
    // author: 'author',
  },
  {
    icon: <VisibilityIcon style={{ width:64, height:64, marginTop: "30px", }} />,
    title: 'Monitoreo de Mercancías las 24 hrs del días',
    // author: 'author',
  },
];

function Services() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root} id="services">
        <Grid alignItems="center" justify="space-evenly" container spacing={3} className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className={classes.services}>
              <Typography className={classes.mainTitle} variant="h3">
                Servicios
              </Typography>
              <Typography className={classes.text} variant="h3">
                * Todas nuestras unidades cuentan con rastreo satelital las 24 horas del día.
              </Typography>
              <div className="service-swiper-pagination"></div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <div className={classes.root2}>
              <GridList cellHeight={140} className={classes.gridList} cols={2}>
                {/*
                <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                  <ListSubheader component="div">December</ListSubheader>
                </GridListTile>
                */}
                {tileData.map((tile) => (
                  <GridListTile cols={1} key={tile.title} className={classes.serviceTile}>
                    {tile.icon && tile.icon}
                    {tile.img && <img src={tile.img} alt={tile.title} className={classes.serviceImg} /> }
                    <GridListTileBar
                      title={tile.title}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      // subtitle={<span>by: {tile.author}</span>}
                      /*
                      actionIcon={
                        <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
                          <InfoIcon />
                        </IconButton>
                      }
                      */
                    />
                  </GridListTile>
                ))}
              </GridList>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

export default Services;