import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  title: {
    color: '#183650',
    fontSize: theme.typography.pxToRem(32),
    textAlign: 'justify',
    textTransform: 'uppercase',
    '&::before': {
      borderBottom: '3px solid #34ccff',
      borderRadius: '2px',
      content: '""',
      display: 'block',
      marginBottom: '15px',
      width: '50px',
     },
  },
  text: {
    textAlign: 'justify',
    marginTop: '1em',
    fontSize: theme.typography.pxToRem(18),
  },
  values: {
    '& > span': {
      fontSize: theme.typography.pxToRem(25),
    },
  },
}));

const values = ['Integridad', 'Responsabilidad', 'Compromiso', 'Lealtad', 'Disponibilidad'];

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.title} variant="h3">
            Nuestro Propósito
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className={classes.text}>
            Transportes MAFA, cuenta con el firme propósito de lograr la satisfacción de sus clientes, proporcionándoles servicios especializados de transporte terrestre y logística de mercancías y productos, con altos niveles de calidad y seguridad.
            <br /><br />
            Con tal propósito mantendrá un proceso sistemático de mejora continua y desarrollo sano de sus negocios; que de igual manera le permitan contribuir al desarrollo sostenido de sus empleados, proveedores, clientes y sociedad.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.title} variant="h3">
              Nuestros Valores
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {values.map((value, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <DoneOutlineIcon />
                </ListItemIcon>
                <ListItemText primary={value} className={classes.values} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
