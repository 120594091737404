import React from 'react';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/icons/Menu';
import SwipeableTemporaryDrawer from 'src/area/main/views/drawer.component';

interface StyledTabsProps {
  value: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      // backgroundColor: '#635ee7',
      backgroundColor: 'rgb(84,174,255,1)',
    },
  },
// eslint-disable-next-line react/jsx-props-no-spreading
})((props: StyledTabsProps) => <Tabs style={{ alignSelf: 'center' }} centered {...props} TabIndicatorProps={{ children: <span /> }} />);

interface StyledTabProps {
  label: string;
  onClick?: any;
}

const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(20),
    // marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
    //
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    textTransform: 'none',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    // color: 'red',
    backgroundColor: 'rgb(50,139,176,0.7)',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    flexGrow: 1,
    // position: "absolute",
    position: 'fixed',
    width: '100%',
    zIndex: 3,
  },
  navWrapper: {
    alignItems: 'center',
    // backgroundColor: '#2e1534',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'space-around',
  },
  logo: {},
}));

export default function Navigation(props: any) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const goTo = (id: any, type?: string) => {
    const element = document.getElementById(id);
    if ('element' === type) {
      element?.scrollIntoView({ block: "center", inline: "center", behavior: "smooth" })
    } else {
      const headerOffset = 115;
      //@ts-ignore
      const elementPosition = element.offsetTop;
      const offsetPosition = elementPosition - headerOffset
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.navWrapper}>
        <div className={classes.logo}>
          <img src="image/logo.png" width="156px" height="80" alt="Transportes MAFA Logo" />
        </div>
        <Hidden smDown implementation="css">
          <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
            <StyledTab label="Sobre Nosotros" onClick={() => goTo('welcome')} />
            <StyledTab label="Cobertura" onClick={() => goTo('locations')} />
            <StyledTab label="Servicios" onClick={() => goTo('services', 'element')} />
            <StyledTab label="Ubicación" onClick={() => goTo('location')} />
            <StyledTab label="Contacto" onClick={() => goTo('contact')} />
          </StyledTabs>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerToggle}
            size="medium"
            onClick={() => setOpen(!open)}
          >
            <Menu fontSize="large" htmlColor="rgb(255,255,255,1)" />
          </IconButton>
        </Hidden>
      </div>
      <SwipeableTemporaryDrawer isOpen={open} goTo={goTo} setOpen={setOpen} />
    </div>
  );
}
