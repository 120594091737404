import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import GroupIcon from '@material-ui/icons/Group';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

type Props = {
  number: string | number,
  text: string,
  icon: string,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'transparent',
      display: 'flex',
      boxShadow: 'none'
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
  }),
);

const Icon = (props: {type: string}) => {
  const iconProps = { htmlColor: "white", style: { fontSize: 65, alignSelf: 'center' } };
  switch(props.type) {
    case 'AssignmentTurnedInIcon':
      return <AssignmentTurnedInIcon {...iconProps} />;
    case 'LocalShippingIcon':
      return <LocalShippingIcon {...iconProps} />;
    case 'GroupIcon':
      return <GroupIcon {...iconProps} />;
    case 'ThumbUpIcon':
        return <ThumbUpIcon {...iconProps} />;
  }
  return null;
};

export default function CardNumber(props: Props) {
  const classes = useStyles();
  const { icon, number, text } = props;
  return (
    <Card className={classes.root}>
      <Icon type={icon} />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h3" variant="h3">
            {number}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {text}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
