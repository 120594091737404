import React, { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, /* Theme */} from '@material-ui/core/styles';
// import Header from 'src/area/main/views/header.component';
import { Hero } from 'src/area/main/views/hero.component';
// import ListIcon from 'src/shared/components/list-icon.component';
import Navigation from 'src/area/main/views/navigation.component';
import Welcome from 'src/area/main/views/welcome.component';
import Numbers from 'src/area/main/views/numbers.component';
import Clients from 'src/area/main/views/clients.component';
import Services from 'src/area/main/views/services.component';
import LocationMap from 'src/area/main/views/location-map.component';
import Locations from 'src/area/main/views/locations.component';
import Contact from 'src/area/main/views/contact.component';
// import logo from './logo.svg';
// import Header from 'src/shared/components/Header.component';
// import { init } from 'src/smooth-scroll';
import theme from './custom.theme';
import './App.css';

const useStyles = makeStyles(() => createStyles({
  /*
  info: {
    backgroundColor: 'rgb(0,0,0,0.7)',
    position: 'relative',
    top: '-88px',
    zIndex: 2,
  },
  */
  section : {
    marginTop: '40px',
    marginBottom: '40px',
  },
}));

function App() {
  const classes = useStyles();
  useEffect(() => {
    // init();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App" style={{ overflow: 'hidden' }}>
        <Navigation />
        {/* <Header /> */}
        <div className="hero" style={{ position: 'relative' }}>
          <Hero />
        </div>
        {/*
        <div className={classes.info}>
          <ListIcon />
        </div>
        */}
        <section id="welcome" className={classes.section}>
          <Welcome />
        </section>
        <section>
          <Numbers />
        </section>
        <section id="locations" className={classes.section}>
          <Locations />
        </section>
        <section id="services">
          <Services />
        </section>
        {/*
        <section className={classes.section}>
          <Clients />
        </section>
        */}
        <section id="location">
          <LocationMap />
        </section>
        <section id="contact" className={classes.section}>
          <Contact />
        </section>
      </div>
    </ThemeProvider>
  );
}

export default App;
