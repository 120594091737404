import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Slider from 'src/area/main/views/slider.component';
import anime from 'animejs/lib/anime.es';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    video: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      maxHeight: '100vh',
    },
    overlay: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 1,
      background: "rgba(0, 0, 0, .8)",
      backgroundSize: "cover",
    },
    slogan: {
      position: 'absolute',
      top: '45%',
      zIndex: 2,
      textAlign: 'center',
      width: '100%',
      color: 'white',
    }
  }),
);

export const Hero = () => {
  const classes = useStyles();
  useEffect(() => {
    // Wrap every letter in a span
    var textWrapper: any = document.querySelector('.ml1 .letters');
    textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: true})
      .add({
        targets: '.ml1 .letter',
        scale: [0.3,1],
        opacity: [0,1],
        translateZ: 0,
        easing: "easeOutExpo",
        duration: 600,
        delay: (el: any, i: any) => 70 * (i+1)
      }).add({
        targets: '.ml1 .line',
        scaleX: [0,1],
        opacity: [0.5,1],
        easing: "easeOutExpo",
        duration: 700,
        offset: '-=875',
        delay: (el: any, i: any, l: any) => 80 * (l - i)
      }).add({
        targets: '.ml1',
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      });
  }, []);
  /*
  <div>
    <div className={classes.overlay}></div>
    <video 
      autoPlay
      className={classes.video}
      playsInline
      loop
      muted
      poster="image/logo.png"
      width="640"
      height="360"
    >
      <source src="video/video-cargo-1.mp4" type="video/mp4" />
      <source src="video/video-cargo-1.webm" type="video/webm" />
    </video>
  </div>
  */
  return (
    <>
      <div className={classes.slogan}>
        <h1 className="ml1">
          <span className="text-wrapper">
          <span className="line line1"></span>
          <span className="letters">Logística en Movimiento</span>
          <span className="line line2"></span>
          </span>
        </h1>
      </div>
      <Slider />
    </>
  );
}
